import { AppStoreConstructorArgs, AppSubStore, AppSubStoreArgs } from "@/store/types";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { asyncResultModule } from "@/modules/async-result";
import { AsyncResult } from "@/modules/async-result/types";
import { AppStoreQueriesCacheStore } from "@/store/queries/AppStoreQueriesCacheStore";
import { AppStoreSpaceStore } from "@/store/space/AppStoreSpaceStore";
import { AppStoreSpaceAccountStore } from "@/store/space-account/AppStoreSpaceAccountStore";
import { AppStoreRoutingStore } from "@/store/routing/AppStoreRoutingStore";
import { AppStoreNavigationStore } from "@/store/navigation/AppStoreNavigationStore";
import { AppStoreAccountStore } from "@/store/account/AppStoreAccountStore";
import pRetry from "p-retry";
import { initializeAccountObservability } from "@/store/utils/initialization";
import { BaseError, StoreError } from "@/domains/errors";
import { PublicAppStore } from "@/store/PublicAppStore";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import localDb from "@/domains/local-db";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { AppStorePlatformInfoStore } from "@/store/platform-info/AppStorePlatformInfoStore";
import { AppStorePlatformSubscriptionStore } from "@/store/platform-subscription/AppStorePlatformSubscriptionStore";
import { AppStoreSyncStore } from "@/store/sync/AppStoreSyncStore";
import { AppStoreNoteStore } from "@/store/note/AppStoreNoteStore";
import { AppStoreCollectionsStore } from "@/store/collections/AppStoreCollectionsStore";
import { AppStoreCollectionItemsStore } from "@/store/collection-items/AppStoreCollectionItemsStore";
import { AppStoreFavoriteItemsStore } from "@/store/favorite-items/AppStoreFavoriteItemsStore";
import { AppStoreSavedSearchesStore } from "@/store/saved-searches/AppStoreSavedSearchesStore";
import { AppStoreSpaceAccountNotesStore } from "@/store/recent-items/AppStoreSpaceAccountNotesStore";
import { AppStoreSpaceAccountCollectionsStore } from "@/store/recent-items/AppStoreSpaceAccountCollectionsStore";
import { AppStoreRecentItemsStore } from "@/store/recent-items/AppStoreRecentItemsStore";
import { AppStoreContactsStore } from "@/store/contacts/AppStoreContactsStore";
import { AppStoreCollectionMetadataStore } from "@/store/collections/AppStoreCollectionMetadataStore";
import { AppStoreSpaceAccountContactsStore } from "@/store/contacts/AppStoreSpaceAccountContactsStore";
import { ModalsStore } from "@/store/modals/ModalsStore";
import { ProactiveLostAccessModalStore } from "@/store/modals/ProactiveLostAccessModalStore";
import { AppStoreChatConversationStore } from "@/store/chat/ChatConversationStore";
import { AppStoreChatMessageStore } from "@/store/chat/ChatMessageStore";
import { AppStoreSpaceAccountChatMessagesStore } from "@/store/chat/AppStoreSpaceAccountChatMessagesStore";
import { AppStoreNoteContentDocumentStore } from "@/store/note/AppStoreNoteContentDocumentStore";
import { AppStoreSidePanelStore } from "@/store/routing/AppStoreSidePanelStore";
import { AppStoreSpaceAccountFeatureFlagsStore } from "./feature-flags/AppStoreSpaceAccountFeatureFlagsStore";
import { windowModule } from "@/modules/window";
import { AppStoreSpaceAccountTopicsStore } from "@/store/topics/AppStoreSpaceAccountTopicStore";
import { AppStoreSpaceAccountTopicItemsStore } from "@/store/topics/AppStoreSpaceAccountTopicItemStore";
import { MemDB } from "@/domains/db";
import { clientEnvModule } from "@/modules/client-env";
import { electronModule } from "@/modules/electron";
import { Search } from "@/domains/search";
import { AppStoreDataImportsStore } from "@/store/data-imports/AppStoreDataImportsStore";
import { AppStoreDataExportsStore } from "@/store/data-exports/AppStoreDataExportsStore";
import { AppStoreAccountTransitionStore } from "@/store/account-transition/AppStoreAccountTransitionStore";
import { AppStoreSpaceAccountAppCalloutsStore } from "@/store/app-callout/AppStoreSpaceAccountAppCalloutsStore";
import { AppStoreApiKeysStore } from "@/store/api-keys/AppStoreApiKeysStore";
import { AppStoreSpaceAccountTemplateStore } from "@/store/templates/AppStoreSpaceAccountTemplateStore";
import { AppStoreTemplateContentDocumentStore } from "@/store/templates/AppStoreTemplateContentDocumentStore";
import { AppStoreTemplateStore } from "@/store/templates/AppStoreTemplateStore";
import { LogoutState } from "@/components/sync/types";
import { TabLifecycleManager, TabLifecycleState } from "@/store/tab-lifecycle";
import { TabDetector } from "@/store/tab-detector";

export class AppStore {
  apiKeys: AppStoreApiKeysStore;
  account: AppStoreAccountStore;
  accountTransition: AppStoreAccountTransitionStore;
  chatConversations: AppStoreChatConversationStore;
  chatMessages: AppStoreChatMessageStore;
  collectionItems: AppStoreCollectionItemsStore;
  collectionMetadata: AppStoreCollectionMetadataStore;
  collections: AppStoreCollectionsStore;
  contacts: AppStoreContactsStore;
  dataImports: AppStoreDataImportsStore;
  dataExports: AppStoreDataExportsStore;
  favoriteItems: AppStoreFavoriteItemsStore;
  modals: ModalsStore;
  navigation: AppStoreNavigationStore;
  notes: AppStoreNoteStore;
  noteContentDocuments: AppStoreNoteContentDocumentStore;
  platformInfo: AppStorePlatformInfoStore;
  platformSubscription: AppStorePlatformSubscriptionStore;
  proactiveLostAccessModal: ProactiveLostAccessModalStore;
  publicAppStore: PublicAppStore;
  queriesCache: AppStoreQueriesCacheStore;
  recentItems: AppStoreRecentItemsStore;
  routing: AppStoreRoutingStore;
  savedSearches: AppStoreSavedSearchesStore;
  sidePanel: AppStoreSidePanelStore;
  spaceAccountAppCallouts: AppStoreSpaceAccountAppCalloutsStore;
  spaceAccountChatMessages: AppStoreSpaceAccountChatMessagesStore;
  spaceAccountCollections: AppStoreSpaceAccountCollectionsStore;
  spaceAccountContacts: AppStoreSpaceAccountContactsStore;
  spaceAccountNotes: AppStoreSpaceAccountNotesStore;
  spaceAccountFeatureFlags: AppStoreSpaceAccountFeatureFlagsStore;
  spaceAccountTemplates: AppStoreSpaceAccountTemplateStore;
  spaceAccountTopics: AppStoreSpaceAccountTopicsStore;
  spaceAccountTopicItems: AppStoreSpaceAccountTopicItemsStore;
  spaceAccounts: AppStoreSpaceAccountStore;
  spaces: AppStoreSpaceStore;
  sync: AppStoreSyncStore;
  templates: AppStoreTemplateStore;
  templateContentDocuments: AppStoreTemplateContentDocumentStore;

  accountLoadingState: AsyncResult<boolean> = asyncResultModule.setLoading();
  mountedState: AsyncResult<boolean> = asyncResultModule.setLoading();
  search: Search;
  tabLifecycleManager: TabLifecycleManager;
  tabDetector: TabDetector;

  protected _memDb: MemDB | undefined;

  constructor({ publicAppStore, api, pusher }: AppStoreConstructorArgs) {
    const injectedDeps: AppSubStoreArgs = { store: this, api, pusher };

    this.publicAppStore = publicAppStore;
    this.tabLifecycleManager = new TabLifecycleManager();
    this.tabDetector = new TabDetector();
    this.apiKeys = new AppStoreApiKeysStore(injectedDeps);
    this.account = new AppStoreAccountStore(injectedDeps);
    this.accountTransition = new AppStoreAccountTransitionStore(injectedDeps);
    this.chatConversations = new AppStoreChatConversationStore(injectedDeps);
    this.chatMessages = new AppStoreChatMessageStore(injectedDeps);
    this.collectionItems = new AppStoreCollectionItemsStore(injectedDeps);
    this.collectionMetadata = new AppStoreCollectionMetadataStore(injectedDeps);
    this.collections = new AppStoreCollectionsStore(injectedDeps);
    this.contacts = new AppStoreContactsStore(injectedDeps);
    this.dataImports = new AppStoreDataImportsStore(injectedDeps);
    this.dataExports = new AppStoreDataExportsStore(injectedDeps);
    this.favoriteItems = new AppStoreFavoriteItemsStore(injectedDeps);
    this.modals = new ModalsStore(injectedDeps);
    this.navigation = new AppStoreNavigationStore(injectedDeps);
    this.notes = new AppStoreNoteStore(injectedDeps);
    this.noteContentDocuments = new AppStoreNoteContentDocumentStore(injectedDeps);
    this.platformInfo = new AppStorePlatformInfoStore(injectedDeps);
    this.platformSubscription = new AppStorePlatformSubscriptionStore(injectedDeps);
    this.queriesCache = new AppStoreQueriesCacheStore();
    this.recentItems = new AppStoreRecentItemsStore(injectedDeps);
    this.routing = new AppStoreRoutingStore(injectedDeps);
    this.savedSearches = new AppStoreSavedSearchesStore(injectedDeps);
    this.spaceAccountAppCallouts = new AppStoreSpaceAccountAppCalloutsStore(injectedDeps);
    this.spaceAccountChatMessages = new AppStoreSpaceAccountChatMessagesStore(injectedDeps);
    this.spaceAccountCollections = new AppStoreSpaceAccountCollectionsStore(injectedDeps);
    this.spaceAccountContacts = new AppStoreSpaceAccountContactsStore(injectedDeps);
    this.spaceAccountNotes = new AppStoreSpaceAccountNotesStore(injectedDeps);
    this.spaceAccountFeatureFlags = new AppStoreSpaceAccountFeatureFlagsStore(injectedDeps);
    this.spaceAccountTemplates = new AppStoreSpaceAccountTemplateStore(injectedDeps);
    this.spaceAccountTopics = new AppStoreSpaceAccountTopicsStore(injectedDeps);
    this.spaceAccountTopicItems = new AppStoreSpaceAccountTopicItemsStore(injectedDeps);
    this.spaceAccounts = new AppStoreSpaceAccountStore(injectedDeps);
    this.spaces = new AppStoreSpaceStore(injectedDeps);
    this.search = new Search({ store: this });
    this.templates = new AppStoreTemplateStore(injectedDeps);
    this.templateContentDocuments = new AppStoreTemplateContentDocumentStore(injectedDeps);

    // This needs to happen after every store that has a ShareSheetModalStore (Note, Template, Collection).
    this.sidePanel = new AppStoreSidePanelStore(injectedDeps);

    // This needs to be initialized after all the stores are initialized
    this.sync = new AppStoreSyncStore(injectedDeps);
    makeAutoObservable(this);

    // We need this to be already observable to react to changes its observable props.
    this.proactiveLostAccessModal = new ProactiveLostAccessModalStore(injectedDeps);
  }

  get auth() {
    return this.publicAppStore.auth;
  }

  get interface() {
    return this.publicAppStore.interface;
  }

  get debug() {
    return this.publicAppStore.debug;
  }

  get memDb(): MemDB {
    if (!this._memDb) {
      throw new Error("Attempt to access MemDB before initialization");
    }

    return this._memDb;
  }

  get isMemDbReady() {
    return this._memDb !== undefined;
  }

  /**
   * Fetches data which is critical to our logged-in routes:
   * - Account info for the current user
   * - Spaces for the current user
   * - SpaceAccounts for the current user
   *
   * Note that the UI will block while these promises complete.
   * (Ideally, they implement some form of caching/persistence)
   */
  async initialize() {
    try {
      runInAction(() => {
        this.accountLoadingState = asyncResultModule.setLoading();
      });

      if (!this.auth.isAuthenticated) {
        throw new StoreError({
          message: "User must be authenticated to initialize the AppStore.",
        });
      }

      const retryOptions = {
        retries: 2,
        minDelay: 1000,
        factor: 2,
      };

      const promises = [
        pRetry(() => this.account.initialize(), retryOptions),
        pRetry(() => this.spaces.initialize(), retryOptions),
        pRetry(() => this.spaceAccounts.initialize(), retryOptions),
        pRetry(() => this.platformInfo.initialize(), retryOptions),
      ];

      /**
       * If it fails even after retries, we throw.
       */
      await Promise.all(promises);

      /**
       * Trigger any side-effects related to initializing the UI client.
       * (Wiring up user info to our error-monitor, etc.)
       */
      await initializeAccountObservability({ store: this });
      await localDb.initializeUserDb();
      await this.initMemDB();
      await this.checkAndSetDefaultLogoutState();

      this.addPageLifecycleReactions();

      runInAction(() => {
        this.accountLoadingState = asyncResultModule.setReady(true);
      });
    } catch (unknownErr) {
      const err = unknownErr as BaseError;

      logger.error({
        message: "[initialize] AppStore failed to initialize.",
        info: { err: objectModule.safeErrorAsJson(err) },
      });

      runInAction(() => {
        this.accountLoadingState = asyncResultModule.setError(err);
      });

      throw err;
    }
  }

  async initMemDB() {
    logger.info({
      message: "[SYNC][MemDB] Initializing MemDB",
    });
    // Note: we don't have to open db connection here, it will be open on first request
    this._memDb = new MemDB(this.spaceAccounts.myPersonalSpaceAccountId);

    await this._memDb.persist();

    this.addDbListeners();
  }

  async checkAndSetDefaultLogoutState() {
    // LogoutState.LoggingOut means we interrupted a logout previously.
    // In that case, the safest bet is force user to login even if they still have auth token
    // Thats because we cannot guarantee how much data has been deleted
    const currentLogoutState = await this.memDb.settings.getLogoutState();
    logger.info({
      message: "[SYNC] Checking and setting default logout state",
      info: { currentLogoutState: currentLogoutState || "undefined" },
    });
    if (currentLogoutState !== LogoutState.LoggingOut) {
      await this.memDb.settings.setLogoutState(LogoutState.Idle);
    }
  }

  /**
   * Represents when the store is ready for use by child components.
   */
  get readyState(): AsyncResult<boolean> {
    const isError = this.accountLoadingState.error || this.mountedState.error;
    const isReady = this.accountLoadingState.data && this.mountedState.data;

    return {
      called: true,
      loading: !isReady,
      error: isError,
      data: isReady,
    } as AsyncResult<boolean>;
  }

  useInitializeAppStoreEffects = () => {
    this.queriesCache.useCacheCancellation();
    this.routing.useSyncRoutingParams();
    this.navigation.useSyncNavigation();

    useEffectOnMount(() => {
      const asyncLogic = async () => {
        await this.sync.initialize();
        await this.sidePanel.initialize();

        /**
         * If the user just logged in, we have a few extra pieces of
         * data we want to fetch for onboarding, v2-transition, etc.
         *
         * (Note that this will hold-up the initial load.)
         */
        if (this.auth.loggedInDuringThisSession) {
          const retryOptions = {
            retries: 2,
            minDelay: 1000,
            factor: 2,
          };

          /**
           * If this fails, we don't currently do anything.
           */
          await pRetry(() => this.accountTransition.fetchTransitionContent(), retryOptions);
        }

        /**
         * We set this in a useEffect because it should happen
         * -after- the initial render, so the `useEffect` hooks
         * from above have a chance to run first.
         */
        runInAction(() => {
          this.mountedState = asyncResultModule.setReady(true);
        });
      };

      asyncLogic();

      /**
       * We kick off any initializers on-mount.
       */
      return () => {
        if (import.meta.hot) {
          return;
        }
        /**
         * We only trigger the `unmount` side-effect if we're not hot-reloading.
         * During hot-reload, we can just keep the state as-is.
         */
        this.debug.resetState();
        this.account.resetState();
        this.accountTransition.resetState();
        this.notes.resetState();
        this.spaces.resetState();
        this.spaceAccounts.resetState();
        this.routing.resetState();
      };
    });
  };

  public forceHardReload = async () => {
    windowModule.forceHardReload();
  };

  public forceUpgradeClient = async () => {
    if (!clientEnvModule.isDesktop()) {
      setTimeout(() => {
        this.forceHardReload();
      }, 300);
      return;
    }

    await electronModule.updateNow({ waitForUpdate: true });
  };

  public resetStorageAndReload = async () => {
    await localDb.queue.clear();
    await localDb.syncUpdates.clear();
    await localDb.sync.clear();

    await this.memDb.syncUpdates.clear();
    await this.memDb.queue.processing.clear();
    await this.memDb.queue.pending.clear();
    await this.memDb.queue.optimisticUpdates.clear();
    await this.memDb.settings.clearNoteContentPreloadingState();
    await this.memDb.settings.clearLastSyncId();

    setTimeout(() => {
      windowModule.forceReload();
    }, 300);
  };

  public resetAndReinitializeSync = () => {
    this.stopSync();
    this.resetSync();
    this.sync.initialize();
  };

  stopSync = () => {
    for (const value of Object.values(this)) {
      if (value instanceof AppSubStore) {
        value.stopSync();
      }
    }
  };

  resetSync = () => {
    for (const value of Object.values(this)) {
      if (value instanceof AppSubStore) {
        value.resetSync();
      }
    }
  };

  addDbListeners() {
    this.memDb.on("versionchange", () => {
      logger.info({
        message: "[MemDB] version changed",
      });

      // TODO: show modal dialog with "version changed, please refresh" message
      // const isDesktop = clientEnvModule.isDesktop();
    });
  }

  // When tab freezes we want to close db connection and allow other tabs (if any) to continue operating
  // If we don't, the app can get into the state it's no longer able to converse with indexedDb
  // Based on this recommendation: https://developer.chrome.com/docs/web-platform/page-lifecycle-api

  // When tab is hidden, we want to pause the Q
  // When tab exits hidden state, we want to resume the Q (assuming tab is not frozen)
  private addPageLifecycleReactions = () => {
    reaction(
      () => this.tabLifecycleManager.state,
      (tabState, previousTabState) => {
        if (tabState === TabLifecycleState.Frozen) {
          return this.onFreeze();
        }

        if (previousTabState === TabLifecycleState.Frozen) {
          return this.onUnfreeze();
        }

        if (tabState === TabLifecycleState.Hidden) {
          return this.onHide();
        }

        if (previousTabState === TabLifecycleState.Hidden) {
          return this.onShow();
        }
      }
    );
  };

  // when tab is hidden we want to pause the queue
  private onHide = () => {
    this.tabDetector.hasOtherOpenTabs().then(hasOtherTabs => {
      if (!hasOtherTabs) {
        logger.debug({
          message: "[MemDB][Sync] No other tabs detected, skip pausing",
          info: {
            tabState: this.tabLifecycleManager.state,
            queueState: this.sync.actionQueue.processingState,
            tabId: this.tabDetector.tabId,
          },
        });

        return;
      }

      if (this.tabLifecycleManager.state !== TabLifecycleState.Hidden) {
        return;
      }

      logger.info({
        message: "[MemDB][Sync] Pausing queue",
        info: {
          tabState: this.tabLifecycleManager.state,
          queueState: this.sync.actionQueue.processingState,
          tabId: this.tabDetector.tabId,
        },
      });

      this.sync.actionQueue.pause();
    });
  };

  // when tab is shown we want to resume the queue
  private onShow = () => {
    logger.info({
      message: "[MemDB][Sync] Resuming queue",
      info: {
        tabState: this.tabLifecycleManager.state,
        queueState: this.sync.actionQueue.processingState,
        tabId: this.tabDetector.tabId,
      },
    });

    this.sync.actionQueue.resume();
  };

  // when tab freezes we want to pause the queue and close the db connection
  private onFreeze = () => {
    logger.info({
      message: "[MemDB][Sync] Freezing app",
      info: {
        tabState: this.tabLifecycleManager.state,
        queueState: this.sync.actionQueue.processingState,
        tabId: this.tabDetector.tabId,
      },
    });

    this.sync.actionQueue.pause();
    this.memDb.close();
  };

  // when tab unfreeze we want to reopen db and potentially resume the queue
  private onUnfreeze = () => {
    logger.info({
      message: "[MemDB][Sync] Unfreezing app",
      info: {
        tabState: this.tabLifecycleManager.state,
        queueState: this.sync.actionQueue.processingState,
        tabId: this.tabDetector.tabId,
      },
    });

    if (this.memDb.isOpen()) {
      this.resumeAndPrefillQueueIfTabNotHidden();
      return;
    }

    this.memDb.open().then(() => {
      // there seems to be a bug, when DB is already open,
      // it doesn't fulfill this promise hence check above
      this.resumeAndPrefillQueueIfTabNotHidden();
    });
  };

  // if we are not hidden, we want to resume the queue and prefill it with latest from disk
  private resumeAndPrefillQueueIfTabNotHidden = () => {
    // grace period
    setTimeout(() => {
      if (this.tabLifecycleManager.state === TabLifecycleState.Hidden) {
        logger.info({
          message: "[MemDB][Sync] Tab is hidden, skipping resuming and prefilling queue",
        });
        return;
      }

      logger.info({
        message: "[MemDB][Sync] Tab is not hidden, proceeding resume and prefill queue",
        info: {
          tabState: this.tabLifecycleManager.state,
          queueState: this.sync.actionQueue.processingState,
          tabId: this.tabDetector.tabId,
        },
      });

      this.sync.actionQueue.updateFromLocal();
      this.sync.actionQueue.resume();
    }, 2000);
  };
}
